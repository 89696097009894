import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CardTitle, Container, Button } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const tableData = [
  {
    name: "Tax A",
    status: "Active",
    createdDate: "2011/04/25",
    rate: "10"
  }
];

const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    sort: true
  },
  {
    dataField: "rate",
    text: "Rate (%)",
    sort: false
  },
  {
    dataField: "status",
    text: "Status",
    sort: true
  }
];

const RowSelectionTable = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };

  return (
    <Fragment>
      <CardTitle tag="h5">
        Tax Rates
        <Button
          tag={Link}
          to="/settings/new-tax"
          color="primary"
          className="float-right mt-n1"
        >
          <FontAwesomeIcon icon={faPlus} /> New
        </Button>
      </CardTitle>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="name"
        data={tableData}
        columns={tableColumns}
        selectRow={selectRow}
        pagination={paginationFactory({
          sizePerPage: 10,
          sizePerPageList: [5, 10, 25, 50]
        })}
      />
      {/* </CardBody> */}
    </Fragment>
  );
};

const TaxSettings = () => (
  <Container fluid className="p-0 container-fluid">
    <RowSelectionTable />
  </Container>
);

export default TaxSettings;
