import React from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/actions/auth";
import { Link, withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CustomInput,
  Input,
  UncontrolledAlert,
  FormFeedback
} from "reactstrap";

import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";

import avatar from "../../assets/img/avatars/avatar.jpg";

// Externals
import validate from "validate.js";
import _ from "underscore";

// Form validation schema
import schema from "./SignInSchema";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: "",
        password: ""
      },
      errors: {
        email: null,
        password: null
      },
      touched: {
        email: false,
        password: false
      },
      email: "",
      password: "",
      submitted: false,
      emailValid: true,
      passwordValid: true
    };
  }

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const { errors, touched, isValid, values } = this.state;
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
    if (
      (field === "email" && !(touched.email && errors.email)) ||
      values.email !== ""
    ) {
      this.setState({
        emailValid: true
      });
    }
    if (
      (field === "password" && !(touched.password && errors.password)) ||
      values.password !== ""
    ) {
      this.setState({
        passwordValid: true
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { errors, touched, isValid, values } = this.state;
    if ((touched.email && errors.email) || values.email === "") {
      this.setState({
        emailValid: false
      });
    } else if (
      (touched.password && errors.password) ||
      values.password === ""
    ) {
      this.setState({
        passwordValid: false
      });
    }
    if (values.email === "" && values.password === "") {
      this.setState({
        emailValid: false,
        passwordValid: false
      });
    }
    const { email, password } = this.state.values;
    if (isValid) {
      console.log("da");
      this.props.login(email, password).then(user => {
        if (user) {
          if (user.status === 200) {
            this.props.history.push("/dashboard");
          }
        }
      });
    }
  };
  render() {
    const { alert } = this.props;
    const { values, errors, touched, isValid } = this.state;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;
    const showPasswordError =
      touched.password && errors.password ? errors.password[0] : false;

    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h2>Welcome back, Chris</h2>
          <p className="lead">Sign in to your account to continue</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <div className="text-center">
                <img
                  src={avatar}
                  alt="Chris Wood"
                  className="img-fluid rounded-circle"
                  width="132"
                  height="132"
                />
              </div>
              <Form>
                {alert.type === "alert-danger" && (
                  <FormGroup>
                    <UncontrolledAlert
                      color="danger"
                      className="alert-outline-coloured"
                    >
                      <div className="alert-icon">
                        <FontAwesomeIcon icon={faBell} fixedWidth />
                      </div>
                      <div className="alert-message">
                        Email/password entered does not match.
                      </div>
                    </UncontrolledAlert>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    onChange={event =>
                      this.handleFieldChange("email", event.target.value)
                    }
                    invalid={!this.state.emailValid}
                    required
                  />
                  {!this.state.emailValid && this.state.values.email === "" && (
                    <FormFeedback>Email is required</FormFeedback>
                  )}
                  {showEmailError && (
                    <FormFeedback>{errors.email[0]}</FormFeedback>
                  )}
                  {/* <AvFeedback>Email is required</AvFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    onChange={event =>
                      this.handleFieldChange("password", event.target.value)
                    }
                    invalid={!this.state.passwordValid}
                    required
                  />
                  {!this.state.emailValid &&
                    this.state.values.password === "" && (
                      <FormFeedback>Password is required</FormFeedback>
                    )}
                  {showPasswordError && (
                    <FormFeedback>{errors.password[0]}</FormFeedback>
                  )}
                  {/* <AvFeedback>Password is required</AvFeedback> */}
                  <small>
                    <Link to="/auth/forgot-password">Forgot password?</Link>
                  </small>
                </FormGroup>

                <div>
                  <CustomInput
                    type="checkbox"
                    id="rememberMe"
                    label="Remember me next time"
                    defaultChecked
                  />
                </div>

                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout,
  getUser: authActions.getuser
};

export default withRouter(connect(mapState, actionCreators)(SignIn));
