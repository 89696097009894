import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import authentication from "./authReducer";
import userReducer from "./userReducer";
import { alert } from "./alertReducer";
import organizationReducer from "./organizationReducer";
import locationReducer from "./locationReducer";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  layout,
  theme,
  alert,
  toastr,
  authentication,
  userReducer,
  organizationReducer,
  locationReducer
});
