import * as types from "../constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ORGANIZATION_REQUEST:
      return {
        loading: true
      };
    case types.GET_ORGANIZATION_SUCCESS:
      return action.organization;

    case types.GET_ORGANIZATION_FAILURE:
      return {
        error: action.error
      };
    case types.UPDATE_ORGANIZATION_REQUEST:
      return {
        loading: true
      };
    case types.UPDATE_ORGANIZATION_SUCCESS:
      return {};

    case types.UPDATE_ORGANIZATION_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
