import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardLink,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { Plus, Trash2, HelpCircle } from "react-feather";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Quantities = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
  { value: 29, label: 29 },
  { value: 30, label: 30 },
  { value: 31, label: 31 },
  { value: 32, label: 32 },
  { value: 33, label: 33 },
  { value: 34, label: 34 },
  { value: 35, label: 35 },
  { value: 36, label: 36 },
  { value: 37, label: 37 },
  { value: 38, label: 38 },
  { value: 39, label: 39 },
  { value: 40, label: 40 },
  { value: 41, label: 41 },
  { value: 42, label: 42 },
  { value: 43, label: 43 },
  { value: 44, label: 44 },
  { value: 45, label: 45 },
  { value: 46, label: 46 },
  { value: 47, label: 47 },
  { value: 48, label: 48 },
  { value: 49, label: 49 },
  { value: 50, label: 50 }
];

const tax = [{ value: "Tax A[10%]", label: "Tax A[10%]" }];

const options = [
  { value: "box", label: "Box" },
  { value: "cm", label: "cm" },
  { value: "dz", label: "dz" },
  { value: "ft", label: "ft" },
  { value: "g", label: "g" },
  { value: "In", label: "In" },
  { value: "kg", label: "kg" },
  { value: "km", label: "km" },
  { value: "lb", label: "lb" },
  { value: "mg", label: "mg" },
  { value: "m", label: "m" },
  { value: "pcs", label: "pcs" }
];
const attribute = [
  { value: "Size", label: "Size" },
  { value: "Material", label: "Material" },
  { value: "Model", label: "Model" },
  { value: "Color", label: "Color" }
];

const attributeOptions = [
  { value: "Smalle", label: "Smalle" },
  { value: "Medium", label: "Medium" },
  { value: "Large", label: "Large" },
  { value: "Cotton", label: "Cotton" },
  { value: "Red", label: "Red" },
  { value: "Blue", label: "Blue" }
];

const HorizontalForm = props => {
  return (
    <Container fluid className="p-0">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/items">Items</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Edit #{props.props.item.id}</BreadcrumbItem>
      </Breadcrumb>
      <h1 className="h3 mb-3">Item #{props.props.item.id}</h1>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Basic Details</CardTitle>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Item Name
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="purchase_order"
                  placeholder={props.props.item.name}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Description
              </Label>
              <Col sm={10}>
                <Input type="textarea" name="textarea" rows="3" />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Unit
              </Label>
              <Col sm={10}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={options}
                  placeholder="Select Unit"
                  isSearchable
                  isClearable
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Tax
              </Label>
              <Col sm={10}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={tax}
                  placeholder="Select Tax"
                  isSearchable
                  isClearable
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Dimensions (cm) (Length X Width X Height)
              </Label>

              <Col sm={4}>
                <Input type="text" name="dimentions" placeholder="Dimentions" />
              </Col>
              <Label sm={2} className="text-sm-right">
                Weight (kg)
              </Label>
              <Col sm={4}>
                <Input type="text" name="weight" placeholder="Weight (kg)" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Manufacturer
              </Label>
              <Col sm={4}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={options}
                  placeholder="Select Vendor"
                  isSearchable
                  isClearable
                />
              </Col>
              <Label sm={2} className="text-sm-right">
                Brand
              </Label>
              <Col sm={4}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={options}
                  placeholder="Select Vendor"
                  isSearchable
                  isClearable
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <React.Fragment>
                <Label sm={2} className="text-sm-right">
                  UPC <HelpCircle size={18} id="UPC" />
                </Label>

                <UncontrolledTooltip placement="top" target="UPC">
                  UPC
                </UncontrolledTooltip>
              </React.Fragment>
              <Col sm={4}>
                <Input type="text" name="SKU" placeholder="UPC" />
              </Col>
              <React.Fragment>
                <Label sm={2} className="text-sm-right">
                  MPN <HelpCircle size={18} id="MPN" />
                </Label>
                <UncontrolledTooltip placement="top" target="MPN">
                  MPN
                </UncontrolledTooltip>
              </React.Fragment>
              <Col sm={4}>
                <Input type="text" name="MPN" placeholder="MPN" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <React.Fragment>
                <Label sm={2} className="text-sm-right">
                  EAN <HelpCircle size={18} id="EAN" />
                </Label>
                <UncontrolledTooltip placement="top" target="EAN">
                  EAN
                </UncontrolledTooltip>
              </React.Fragment>

              <Col sm={4}>
                <Input type="text" name="EAN" placeholder="EAN" />
              </Col>
              <React.Fragment>
                <Label sm={2} className="text-sm-right">
                  ISBN <HelpCircle size={18} id="ISBN" />
                </Label>
                <UncontrolledTooltip placement="top" target="ISBN">
                  ISBN
                </UncontrolledTooltip>
              </React.Fragment>

              <Col sm={4}>
                <Input type="text" name="ISBN" placeholder="ISBN" />
              </Col>
            </FormGroup>

            <div>
              <CardHeader>
                <CardTitle tag="h5">Item Variants</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label sm={2} className="text-sm-right pt-sm-0"></Label>
                  <Col sm={10}>
                    <CustomInput
                      type="checkbox"
                      id="returnable_item"
                      label="Returnable Item"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2} className="text-sm-right pt-sm-0"></Label>
                  <Col sm={10}>
                    <CustomInput
                      type="checkbox"
                      id="multiple_items"
                      label="This product has multiple options, like different sizes or colors"
                      checked={props.props.isChecked}
                      onChange={props.handleChecked}
                    />
                  </Col>
                </FormGroup>
                {props.props.isChecked ? (
                  <div>
                    <CardTitle tag="h5">Options</CardTitle>
                    {props.props.productOptions.map((productOptions, idx) => (
                      <div key={idx}>
                        <FormGroup row>
                          <Label sm={2} className="text-sm-right">
                            Option {idx + 1}
                          </Label>
                          <Col sm={3}>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={attribute}
                              placeholder={`Select Option ${idx + 1}`}
                              isSearchable
                              isClearable
                            />
                          </Col>
                          <Label sm={2} className="text-sm-right">
                            Values
                          </Label>
                          <Col sm={3}>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={attributeOptions}
                              placeholder="Select Values"
                              isSearchable
                              isClearable
                              isMulti
                            />
                          </Col>
                          <Col sm={2}>
                            <FormGroup>
                              <Button
                                onClick={props.handleRemoveProductOptions(idx)}
                                color="danger"
                                size="sm"
                                className="mr-1 mb-1 float-right"
                                outline
                              >
                                <Trash2 />
                              </Button>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </div>
                    ))}

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Button
                            onClick={props.handleAddProductOptions}
                            color="primary"
                            className="mr-1 mb-1"
                            outline
                          >
                            <Plus /> Add another Option
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <CardTitle tag="h5">Option</CardTitle>

                    <FormGroup row>
                      <Label sm={2} className="text-sm-right">
                        Option
                      </Label>
                      <Col sm={3}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={attribute}
                          placeholder={`Select Option `}
                          isSearchable
                          isClearable
                        />
                      </Col>
                      <Label sm={2} className="text-sm-right">
                        Values
                      </Label>
                      <Col sm={3}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={attributeOptions}
                          placeholder="Select Values"
                          isSearchable
                          isClearable
                          isMulti
                        />
                      </Col>
                    </FormGroup>
                  </div>
                )}
              </CardBody>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

class ItemRows extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggle = () => {
    this.setState(state => ({
      index: !state.index
    }));
  };

  componentWillMount() {
    this.setState(() => ({
      index: false
    }));
  }

  render() {
    const props = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Items</CardTitle>
        </CardHeader>
        <CardBody>
          <Form>
            <Row form>
              <Col md={2}>
                <FormGroup>
                  <Label>Name</Label>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Quantity</Label>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Selling Price</Label>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Purchase Price</Label>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Reorder Level</Label>
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label>Location</Label>
                </FormGroup>
              </Col>
            </Row>

            <div>
              {props.props.productOptions.map((productOptions, idx) => (
                <div key={idx}>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Input
                          placeholder="Username"
                          defaultValue="M"
                          disbaled="true"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={Quantities}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            LKR
                          </InputGroupAddon>
                          <Input placeholder="Username" defaultValue="130.00" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            LKR
                          </InputGroupAddon>
                          <Input placeholder="Username" defaultValue="130.00" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Input type="text" name="zip" />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <React.Fragment>
                        <CardLink href="#" onClick={() => this.toggle()}>
                          Edit Location
                        </CardLink>
                        <Modal
                          isOpen={this.state.index}
                          toggle={() => this.toggle()}
                          centered
                        >
                          <ModalHeader toggle={() => this.toggle()}>
                            Quantity
                          </ModalHeader>
                          <ModalBody className="text-center m-3">
                            <Form>
                              <Row form>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label className="float-left">
                                      Location Name
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label className="float-left">
                                      Quantity
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      name="date"
                                      placeholder="Location A"
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Select
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      options={Quantities}
                                      placeholder="Select Unit"
                                      isSearchable
                                      isClearable
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      name="date"
                                      placeholder="Location B"
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Select
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      options={Quantities}
                                      placeholder="Select Unit"
                                      isSearchable
                                      isClearable
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="secondary"
                              onClick={() => this.toggle()}
                            >
                              Close
                            </Button>{" "}
                            <Button
                              color="primary"
                              onClick={() => this.toggle()}
                            >
                              Save changes
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </React.Fragment>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Save
    </Button>

    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class NewItems extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    isChecked: true,
    item: this.props.location.state.item,
    productOptions: [{ attribute: "", options: "" }]
  };

  handleChecked = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  handleAddProductOptions = () => {
    this.setState({
      productOptions: this.state.productOptions.concat([
        { attribute: "", options: "" }
      ])
    });
  };

  handleRemoveProductOptions = idx => () => {
    this.setState({
      productOptions: this.state.productOptions.filter(
        (s, sidx) => idx !== sidx
      )
    });
  };
  render() {
    return (
      <Container>
        <HorizontalForm
          props={this.state}
          handleChecked={this.handleChecked}
          handleAddProductOptions={this.handleAddProductOptions}
          handleRemoveProductOptions={this.handleRemoveProductOptions}
        />

        <ItemRows props={this.state} />
        <ActionPanel />
      </Container>
    );
  }
}

export default NewItems;
