import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

const Sales = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Sales</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Sales by customer</li>
        <li>Sales by item</li>
        <li>Order fulfillment by item</li>
        <li>Sales return history</li>
        <li>Paking history</li>
      </ul>
    </CardBody>
  </Card>
);

const Inventory = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Inventory</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Inventory ummery</li>
        <li>Inventory valuation summery</li>
        <li>FIFO Cost Lot Tracking</li>
        <li>Product Sales Report</li>
        <li>Warehouse Report</li>
        <li>Active Purchase Orders Report</li>
        <li>Stock Summary Report</li>
      </ul>
    </CardBody>
  </Card>
);

const Receivables = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Receivables</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Customer Balances</li>
        <li>Invoice Details</li>
        <li>Sales Order Details</li>
      </ul>
    </CardBody>
  </Card>
);

const PaymentsReceived = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Payments Received</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Payments Received</li>
      </ul>
    </CardBody>
  </Card>
);

const Payables = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Payables</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Vendor Balances</li>
        <li>Bills Details</li>
        <li>Payments Made</li>
        <li>Purchase Order Details</li>
        <li>Purchase Orders by Vendor</li>
      </ul>
    </CardBody>
  </Card>
);

const PurchasesAndExpenses = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Purchases And Expenses</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>Purchases by Item</li>
        <li>Receive History</li>
      </ul>
    </CardBody>
  </Card>
);

const Activity = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Activity</CardTitle>
    </CardHeader>
    <CardBody>
      <ul>
        <li>System Mails</li>
        <li>Activity Logs</li>
        <li>API Usage</li>
      </ul>
    </CardBody>
  </Card>
);

const Default = () => (
  <Container fluid className="p-0">
    <h2 className="card-subtitle text-muted">Reports</h2>
    <Row>
      <Col lg="4" className="d-flex">
        <Sales />
      </Col>
      <Col lg="4" className="d-flex">
        <Inventory />
      </Col>
      <Col lg="4" className="d-flex">
        <Receivables />
      </Col>
    </Row>
    <Row>
      <Col lg="4" className="d-flex">
        <PaymentsReceived />
      </Col>
      <Col lg="4" className="d-flex">
        <Payables />
      </Col>
      <Col lg="4" className="d-flex">
        <PurchasesAndExpenses />
      </Col>
    </Row>
    <Row>
      <Col lg="4" className="d-flex">
        <Activity />
      </Col>
    </Row>
  </Container>
);

export default Default;
