import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "react-feather";

const SectionOne = props => (
  <Card>
    <CardBody>
      <Form>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Customer Name
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Sales Order #
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.sales_order}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Reference #
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.refference}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Sales Order Date
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.createdDate}
              disabled
            />
          </Col>
          <Label sm={3} className="text-sm-right">
            Expected Shipment Date
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.createdDate}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Location
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.location}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Salesperson
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Delivery Method
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="sales_order"
              placeholder={props.props.sales.delivery_method}
              disabled
            />
          </Col>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

const ItemRows = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Item details</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Label>Item</Label>
              <Input
                type="text"
                name="sales_order"
                placeholder="Item 1"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Quantity</Label>
              <Input type="text" name="sales_order" placeholder="10" disabled />
            </FormGroup>
          </Col>
          <Col md={1}>
            <FormGroup>
              <Label>Rate</Label>
              <Input type="text" name="sales_order" placeholder="60" disabled />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Discount</Label>
              <Input
                type="text"
                name="sales_order"
                placeholder="10%"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Tax</Label>
              <Input type="text" name="sales_order" placeholder="7%" disabled />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Amount</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">LKR</InputGroupAddon>
                <Input placeholder="Username" defaultValue="130.00" disabled />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={3}>
            <FormGroup>
              <Input
                type="text"
                name="sales_order"
                placeholder="Item 2"
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input type="text" name="sales_order" placeholder="5" disabled />
            </FormGroup>
          </Col>
          <Col md={1}>
            <FormGroup>
              <Input type="text" name="sales_order" placeholder="3" disabled />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input type="text" name="sales_order" placeholder="5%" disabled />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Input type="text" name="sales_order" placeholder="2%" disabled />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">LKR</InputGroupAddon>
                <Input placeholder="Username" defaultValue="130.00" disabled />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Button color="primary" className="mr-1 mb-1" outline>
                <Plus /> Add another Item
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </CardBody>
  </Card>
);

const SectionThree = props => (
  <Card>
    <CardBody>
      <Form>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Customer Notes
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="textarea"
              placeholder={props.props.sales.notes}
              rows="3"
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Terms & Conditions
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="textarea"
              placeholder={props.props.sales.terms_conditions}
              rows="3"
              disabled
            />
          </Col>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Save as Draft
    </Button>
    <Button color="primary" className="mr-1 mb-1">
      Save and Send
    </Button>
    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class HorizontalForm extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    sales: this.props.location.state.sales
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/sales-orders">Sales Orders</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {this.props.location.state.sales.id}
          </BreadcrumbItem>
        </Breadcrumb>

        <Button className="float-right mr-2 mb-1" color="danger">
          Cancel Items
        </Button>
        <Button className="float-right mr-2 mb-1" color="primary">
          Mark Shipment as Fulfilled
        </Button>
        <Button className="float-right mr-2 mb-1" color="primary">
          Create Invoice
        </Button>

        <h1>Sales Order #{this.props.location.state.sales.sales_order}</h1>

        <SectionOne props={this.state} />
        <ItemRows />
        <SectionThree props={this.state} />
        <ActionPanel />
      </Container>
    );
  }
}

export default HorizontalForm;
