import React from "react";
import { Router } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "./redux/actions/userAction";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { requestInterceptor } from "./services/requestInterceptor";
import { responseInterceptor } from "./services/responseInterceptor";
import { lookupService } from "./services/lookupService";

import store from "./redux/store/index";
import Routes from "./routes/Routes";
import { history } from "./helpers";

requestInterceptor();
responseInterceptor();
lookupService.getCountries();
lookupService.getDateFormats();
lookupService.getFiscalYears();
lookupService.getIndustries();
lookupService.getLanguages();
lookupService.getTimeZones();

const App = () => (
  <div>
    <Router history={history}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Router>
  </div>
);

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  getOrganization: userAction.getOrganization
};
export default connect(
  mapState,
  actionCreators
)(App);
