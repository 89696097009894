import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CardTitle, Table } from "reactstrap";

import { Edit2 } from "react-feather";

const StripedRows = () => (
  <Fragment>
    <CardTitle tag="h5">Notifications</CardTitle>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Sales Order Notification</td>
          <td>Sales Order from %CompanyName% (Sales Order #: %SO_Number%)</td>
          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Sales Order Notification"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Invoice Notification</td>
          <td>Invoice - %InvoiceNumber% from %CompanyName%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Invoice Notification"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Purchase Order Notification</td>
          <td>
            Purchase Order from %CompanyName% (Purchase Order #: %PO_Number%)
          </td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Purchase Order Notification"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Payment Thank-you</td>
          <td>Payment Received by %CompanyName%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Payment Thank-you"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Customer Statements</td>
          <td>Customer Statements %CompanyName%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Customer Statements"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Vendor Statements</td>
          <td>Vendor Statements %CompanyName%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Vendor Statements"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Shipping Notification</td>
          <td>Shipping notice # %ShipmentOrderNumber%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Shipping Notification"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
        <tr>
          <td>Delivery Notification</td>
          <td>Delivery notice # %ShipmentOrderNumber%</td>

          <td className="table-action">
            <Link
              to={{
                pathname: "/settings/notification/2",
                state: {
                  name: "Delivery Notification"
                }
              }}
            >
              <Edit2 className="align-middle mr-1" size={18} />
            </Link>
          </td>
        </tr>
      </tbody>
    </Table>
  </Fragment>
);

export default StripedRows;
