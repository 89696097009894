import React from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/actions/auth";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CustomInput,
  Input,
  UncontrolledAlert,
  FormFeedback
} from "reactstrap";

import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

// Externals
import validate from "validate.js";
import _ from "underscore";

// Form validation schema
import schema from "./ForgotPasswordSchema";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: ""
      },
      errors: {
        email: null
      },
      touched: {
        email: false
      },
      email: "",
      password: "",
      submitted: false,
      emailValid: true
    };
  }

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const { errors, touched, isValid, values } = this.state;
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
    if (!(touched.email && errors.email) || values.email !== "") {
      this.setState({
        emailValid: true
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { errors, touched, isValid, values } = this.state;
    if ((touched.email && errors.email) || values.email === "") {
      this.setState({
        emailValid: false
      });
    }
    const { email } = this.state.values;
    if (isValid) {
      this.props.forgotPassword(email);
    }
  };

  render() {
    const { values, errors, touched, isValid } = this.state;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    onChange={event =>
                      this.handleFieldChange("email", event.target.value)
                    }
                    invalid={!this.state.emailValid}
                    required
                  />
                  {!this.state.emailValid && this.state.values.email === "" && (
                    <FormFeedback>Email is required</FormFeedback>
                  )}
                  {showEmailError && (
                    <FormFeedback>{errors.email[0]}</FormFeedback>
                  )}
                  {/* <AvFeedback>Email is required</AvFeedback> */}
                </FormGroup>

                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    Reset password
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  forgotPassword: authActions.forgotPassword
};

export default connect(mapState, actionCreators)(ForgotPassword);
