// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

//Auth
export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";

export const LOGOUT = "USERS_LOGOUT";

export const FORGOT_PASSWORD_REQUEST = "USERS_FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "USERS_FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "USERS_FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "USERS_RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "USERS_RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "USERS_RESET_PASSWORD_FAILURE";

//Alert
export const SUCCESS = "ALERT_SUCCESS";
export const ERROR = "ALERT_ERROR";
export const CLEAR = "ALERT_CLEAR";

//user

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_ORGANIZATION_USERS_REQUEST = "GET_ORGANIZATION_USERS_REQUEST";
export const GET_ORGANIZATION_USERS_SUCCESS = "GET_ORGANIZATION_USERS_SUCCESS";
export const GET_ORGANIZATION_USERS_FAILURE = "GET_ORGANIZATION_USERS_FAILURE";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

//organization

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILURE = "GET_ORGANIZATION_FAILURE";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILURE = "UPDATE_ORGANIZATION_FAILURE";

//locations

export const GET_LOCATIONS_REQUEST = "GET_LOCATIONS_REQUEST";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAILURE = "GET_LOCATIONS_FAILURE";

export const NEW_LOCATIONS_REQUEST = "NEW_LOCATIONS_REQUEST";
export const NEW_LOCATIONS_SUCCESS = "NEW_LOCATIONS_SUCCESS";
export const NEW_LOCATIONS_FAILURE = "NEW_LOCATIONS_FAILURE";
