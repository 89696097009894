import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap";
import { MoreHorizontal, Eye } from "react-feather";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory from "react-bootstrap-table2-paginator";

const tableData = [
  {
    id: 1,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Complete",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 2,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Pending",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 3,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Complete",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 4,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Rejected",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 5,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Complete",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 6,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Pending",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  },
  {
    id: 7,
    name: "Pavan Welihinda",
    delivery_method: "Shipment 1",
    status: "Complete",
    location: "Location A",
    notes: "Notes",
    terms_conditions: "Company Term and Conditons",
    sales_order: "PO-0001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800.00",
    payables: "Rs.320,800.00",
    createdDate: "2011/04/25"
  }
];

const tableColumns = [
  {
    dataField: "sales_order",
    text: "Sales Order #",
    sort: true
  },
  {
    dataField: "refference",
    text: "Refference #",
    sort: true
  },
  {
    dataField: "name",
    text: "Customer",
    sort: true
  },
  {
    dataField: "invoiced",
    text: "Invoiced",
    sort: false
  },
  {
    dataField: "payment",
    text: "Payment",
    sort: false
  },
  {
    dataField: "packed",
    text: "Packed",
    sort: false
  },
  {
    dataField: "createdDate",
    text: "Date Created",
    sort: true
  },
  {
    dataField: "payables",
    text: "Amount",
    sort: false
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    formatter: statusFormatter
  },

  {
    dataField: "actions",
    text: "Action(s)",
    formatter: actionFormatter,
    sort: false
  }
];

function actionFormatter(cell, row) {
  return (
    <Button
      tag={Link}
      to={{
        pathname: `/sales-order/${row.id}`,
        state: {
          sales: row
        }
      }}
      color="outline"
      className="mt-n1"
    >
      <Eye className="align-middle ml-1" size={18} />
    </Button>
  );
}

function statusFormatter(cell, row) {
  if (cell === "Pending" || cell === "Draft") {
    return <span className="badge badge-warning ml-0 mr-1 mb-1">{cell}</span>;
  } else if (cell === "Rejected") {
    return <span className="badge badge-danger ml-0 mr-1 mb-1">{cell}</span>;
  } else {
    return <span className="badge badge-success ml-0 mr-1 mb-1">{cell}</span>;
  }
}
const RowSelectionTable = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };
  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <UncontrolledDropdown>
            <DropdownToggle tag="a">
              <MoreHorizontal />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Mark as Active</DropdownItem>
              <DropdownItem>Mark as Inactive</DropdownItem>
              <DropdownItem>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="id"
          data={tableData}
          columns={tableColumns}
          selectRow={selectRow}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50]
          })}
        />
      </CardBody>
    </Card>
  );
};

const PurchaseOrders = () => (
  <Container fluid className="p-0 container-fluid">
    <UncontrolledButtonDropdown key={0} className="mr-1 mb-3">
      <DropdownToggle caret color={"test"}>
        <span className="h3 mb-3">All Sales Orders </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>All</DropdownItem>
        <DropdownItem>Draft</DropdownItem>
        <DropdownItem>Pending Approval</DropdownItem>
        <DropdownItem>Approved</DropdownItem>
        <DropdownItem>Issued</DropdownItem>
        <DropdownItem>Closed</DropdownItem>
        <DropdownItem>Partially Received</DropdownItem>
        <DropdownItem>Pending Received</DropdownItem>
        <DropdownItem>Received</DropdownItem>
        <DropdownItem>DropShiped</DropdownItem>
        <DropdownItem>Backorder</DropdownItem>
        <DropdownItem>Unbilled Backorder</DropdownItem>
        <DropdownItem>Canceled</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>

    <RowSelectionTable />
  </Container>
);

export default PurchaseOrders;
