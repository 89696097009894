export default {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: {
      message: "is not valid"
    },
    length: {
      maximum: 55
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  }
};
