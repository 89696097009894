import axios from "axios";
import { authHeader } from "../helpers";
export const userService = {
  inviteUser,
  getOrganization,
  getLocations,
  addNewLocation,
  updateOrganization,
  getOrganizationUsers
};

// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

function getOrganization() {
  return axios.get(API_URL + `user`).then(organization => {
    return organization;
  });
}

function updateOrganization(organization, id) {
  return axios({
    method: "put",
    url: API_URL + `organization/${id}`,
    data: organization
  })
    .then(function(response) {
      //handle success
      console.log(response);
      return response;
    })
    .catch(function(response) {
      //handle error
      console.log(response);
      return response;
    });
}

function getLocations() {
  const requestOptions = {
    headers: authHeader()
  };
  return axios.get(API_URL + `locations`).then(organization => {
    return organization;
  });
}

function addNewLocation(location) {
  console.log(location.data);

  return axios({
    method: "post",
    url: API_URL + `locations`,
    data: location
  })
    .then(function(response) {
      //handle success
      // console.log(response);
      return response;
    })
    .catch(function(response) {
      //handle error
      // console.log(response);
      return response;
    });
  // return axios.post(API_URL + `locations`, { location }).then(res => {
  //   return res;
  // });
}

function inviteUser(user) {
  return axios({
    method: "post",
    url: API_URL + `user/invite`,
    data: user
  })
    .then(function(response) {
      //handle success

      return response;
    })
    .catch(function(response) {
      //handle error

      return response;
    });
}

function getOrganizationUsers(id) {
  const requestOptions = {
    headers: authHeader()
  };
  return axios.get(API_URL + `organization/users/${id}`).then(organization => {
    return organization;
  });
}
