import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

const countries = JSON.parse(localStorage.getItem("countries"));

let option = [<option key="0">select country</option>];

let CountryList = countries.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

const CountryOptionList = option.concat(CountryList);

const SectionOne = props => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Horizontal form</CardTitle>
      <h6 className="card-subtitle text-muted">Horizontal Bootstrap layout.</h6>
    </CardHeader>
    <CardBody>
      <AvForm onValidSubmit={props.handleSubmit}>
        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Name
          </Label>
          <Col sm={10}>
            <AvInput
              name="name"
              id="name"
              placeholder="Name"
              required
              onChange={event =>
                props.handleFieldChange("name", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Street 1
          </Label>
          <Col sm={10}>
            <AvInput
              name="street_one"
              id="street_one"
              placeholder="Street one"
              required
              onChange={event =>
                props.handleFieldChange("street_one", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Street 2
          </Label>
          <Col sm={10}>
            <AvInput
              name="street_two"
              id="street_two"
              placeholder="Street two"
              required
              onChange={event =>
                props.handleFieldChange("street_two", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            City
          </Label>
          <Col sm={10}>
            <AvInput
              name="city"
              id="city"
              placeholder="City"
              required
              onChange={event =>
                props.handleFieldChange("city", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Country
          </Label>

          <Col sm={10}>
            <AvField
              type="select"
              name="country_id"
              onChange={event =>
                props.handleFieldChange(
                  "country_id",
                  event.target.selectedIndex
                )
              }
              required
            >
              {CountryOptionList}
            </AvField>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            State
          </Label>
          <Col sm={10}>
            <AvInput
              name="state"
              id="state"
              placeholder="State"
              required
              onChange={event =>
                props.handleFieldChange("state", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Zip Code
          </Label>
          <Col sm={10}>
            <AvInput
              type="number"
              name="zip_code"
              id="zip_code"
              placeholder="Zip Code"
              required
              onChange={event =>
                props.handleFieldChange("zip_code", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Phone
          </Label>
          <Col sm={10}>
            <AvInput
              name="phone_number"
              type="number"
              id="phone_number"
              placeholder="Phone"
              required
              onChange={event =>
                props.handleFieldChange("phone_number", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Email
          </Label>
          <Col sm={10}>
            <AvInput
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              required
              onChange={event =>
                props.handleFieldChange("email", event.target.value)
              }
            />
            <AvFeedback>This is an error!</AvFeedback>
          </Col>
        </AvGroup>
        <Button type="submit" color="primary" className="mr-1 mb-1">
          Send
        </Button>

        <Button color="primary" className="mr-1 mb-1" outline>
          Cancel
        </Button>
      </AvForm>
    </CardBody>
  </Card>
);

// const ActionPanel = () => (
//   <Form>
//     <Button type="submit" color="primary" className="mr-1 mb-1">
//       Send
//     </Button>

//     <Button color="primary" className="mr-1 mb-1" outline>
//       Cancel
//     </Button>
//   </Form>
// );

class NewLocation extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    values: {
      organization_id: localStorage.getItem("organization_id"),
      name: "",
      country_id: "",
      street_one: "",
      street_two: "",
      city: "",
      state: "",
      zip_code: "",
      phone_number: "",
      email: ""
    }
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    // newState.submitError = null;
    // newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSubmit = async event => {
    // event.preventDefault();
    console.log(this.state.values);
    const location = this.state.values;
    this.props.addNewLocations(location);
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings">Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>New Location</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">New Location</h1>
        <SectionOne
          handleFieldChange={this.handleFieldChange}
          handleSubmit={this.handleSubmit}
        />
        {/* 
        <ActionPanel /> */}
      </Container>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  addNewLocations: userAction.addNewLocations
};
export default withRouter(connect(mapState, actionCreators)(NewLocation));
