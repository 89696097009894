import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

const options = [{ value: "admin", label: "Admin" }];

const SectionOne = props => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Horizontal form</CardTitle>
      <h6 className="card-subtitle text-muted">Horizontal Bootstrap layout.</h6>
    </CardHeader>
    <CardBody>
      <AvForm onValidSubmit={props.handleSubmit}>
        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Email
          </Label>
          <Col sm={10}>
            <AvInput
              type="email"
              name="email"
              placeholder="Email"
              onChange={event =>
                props.handleFieldChange("email", event.target.value)
              }
              required
            />
          </Col>
        </AvGroup>
        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            First name
          </Label>
          <Col sm={10}>
            <AvInput
              type="text"
              name="name"
              placeholder="Name"
              onChange={event =>
                props.handleFieldChange("first_name", event.target.value)
              }
              required
            />
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Last name
          </Label>
          <Col sm={10}>
            <AvInput
              type="text"
              name="name"
              placeholder="Name"
              onChange={event =>
                props.handleFieldChange("last_name", event.target.value)
              }
              required
            />
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label sm={2} className="text-sm-right">
            Role
          </Label>
          <Col sm={10}>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={options}
              placeholder="Select or Type to add"
              isSearchable
              isClearable
              onChange={event => props.handleFieldChange("role", event.value)}
              required
            />
          </Col>
        </AvGroup>
        <Button type="submit" color="primary" className="mr-1 mb-1">
          Send
        </Button>

        <Button color="primary" className="mr-1 mb-1" outline>
          Cancel
        </Button>
      </AvForm>
    </CardBody>
  </Card>
);

class InviteUsers extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    values: {
      email: "",
      first_name: "",
      last_name: "",
      organization_id: localStorage.getItem("organization_id"),
      scopes: "pos",
      role: ""
    }
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    // newState.submitError = null;
    // newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSubmit = async event => {
    // event.preventDefault();
    console.log(this.state.values);
    const user = this.state.values;
    this.props.inviteUser(user);
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings">Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Invite User</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">Invite User</h1>
        <SectionOne
          props={this.state}
          handleFieldChange={this.handleFieldChange}
          handleSubmit={this.handleSubmit}
        />
      </Container>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  inviteUser: userAction.inviteUser
};
export default withRouter(connect(mapState, actionCreators)(InviteUsers));
