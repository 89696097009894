import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";
import {
  Button,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

import Select from "react-select";

const baseCurrency = JSON.parse(localStorage.getItem("countries"));
const fiscalYears = JSON.parse(localStorage.getItem("fiscalYears"));
const languages = JSON.parse(localStorage.getItem("languages"));
const timeZones = JSON.parse(localStorage.getItem("timeZones"));
const dateFormats = JSON.parse(localStorage.getItem("dateFormats"));

let option = [<option key="0">select</option>];

let BaseCurrencyList = baseCurrency.data.data.map(data => (
  <option key={data.id}>{data.currency_code}</option>
));

let FiscalYearsList = fiscalYears.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

let LanguagesList = languages.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

let TimezonesList = timeZones.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

let DateFormatsList = dateFormats.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));
const BaseCurrencyOptionList = option.concat(BaseCurrencyList);
const FiscalYearsOptionList = option.concat(FiscalYearsList);
const LanguagesOptionList = option.concat(LanguagesList);
const TimezonesOptionList = option.concat(TimezonesList);
const DateFormatsOptionList = option.concat(DateFormatsList);
// const CountryOptionList = option.concat(CountryList);

const Address = props => {
  // const fi = this.state.organization.fiscal_year_id;
  const defaultValues = props.organization;
  return (
    <Fragment>
      <CardTitle tag="h5">View and update your store details</CardTitle>

      <AvForm model={defaultValues} onValidSubmit={props.handleSubmit}>
        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label>Base Currency</Label>

              <AvField
                type="select"
                name="base_currency_id"
                onChange={event =>
                  props.handleFieldChange(
                    "country_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {BaseCurrencyOptionList}
              </AvField>
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label>Fiscal Year</Label>

              <AvField
                type="select"
                name="fiscal_year_id"
                onChange={event =>
                  props.handleFieldChange(
                    "country_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {FiscalYearsOptionList}
              </AvField>
            </AvGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label>Language</Label>

              <AvField
                type="select"
                name="language_id"
                onChange={event =>
                  props.handleFieldChange(
                    "country_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {LanguagesOptionList}
              </AvField>
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label>Timezone</Label>

              <AvField
                type="select"
                name="timezone_id"
                onChange={event =>
                  props.handleFieldChange(
                    "country_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {TimezonesOptionList}
              </AvField>
            </AvGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label>Date format</Label>

              <AvField
                type="select"
                name="date_format_id"
                onChange={event =>
                  props.handleFieldChange(
                    "country_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {DateFormatsOptionList}
              </AvField>
            </AvGroup>
          </Col>
        </Row>

        <Button color="primary" type="submit">
          Save
        </Button>
      </AvForm>
    </Fragment>
  );
};

class PreferencesSettings extends React.Component {
  state = {
    organization: "",
    values: {
      base_currency_id: "",
      fiscal_year_id: "",
      language_id: "",
      timezone_id: "",
      date_format_id: ""
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      organization: nextProps.organization
    });
    console.log(this.state);
  }

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.values[field] = value;

    this.setState(newState);
  };

  handleSubmit = async event => {
    // event.preventDefault();
    Object.keys(this.state.values).forEach(key => {
      if (this.state.values[key] === "") {
        const newState = { ...this.state };
        newState.values[key] = this.state.organization[key];
      }
    });
    // for (let key in this.state.values) {
    //   if (this.state.values.hasOwnProperty(key)) {
    //     console.log(`${key} : ${this.state.values[key]}`);
    //   }
    // }
    const organization = this.state.values;
    const id = localStorage.getItem("organization_id");
    this.props.updateOrganization(organization, id);
    console.log(this.state.values);
  };

  render() {
    const organization = this.state.organization;
    return (
      <Fragment>
        {organization !== "" && (
          <Address
            organization={organization}
            handleFieldChange={this.handleFieldChange}
            handleSubmit={this.handleSubmit}
          />
        )}
      </Fragment>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  updateOrganization: userAction.updateOrganization
};
export default withRouter(
  connect(mapState, actionCreators)(PreferencesSettings)
);
