import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";
import {
  Button,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";

import { faUpload } from "@fortawesome/free-solid-svg-icons";

import avatar1 from "../../assets/img/avatars/avatar.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import validate from "validate.js";
import _ from "underscore";

// Form validation schema
import schema from "./GeneralSettingsSchema";

const industries = JSON.parse(localStorage.getItem("industries"));
const countries = JSON.parse(localStorage.getItem("countries"));

// const Industries = industries.data.data.map(data => ({
//   value: data.id,
//   label: data.name
// }));

const Countries = countries.data.data.map(data => ({}));

let option = [<option key="0">select</option>];

let IndustryList = industries.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

let CountryList = countries.data.data.map(data => (
  <option key={data.id}>{data.name}</option>
));

const CountryOptionList = option.concat(CountryList);
const IndustryOptionList = option.concat(IndustryList);

const PublicInfo = props => {
  console.log(props);

  const defaultValues = props.organization;
  console.log(defaultValues);

  var country = countries.data.data.filter(function(country) {
    return country.id === props.organization.country_id;
  });
  console.log(country[0].name);
  var industry = industries.data.data.filter(function(industry) {
    return industry.id === props.organization.industry_id;
  });

  return (
    <Fragment>
      <CardTitle tag="h5">Company details</CardTitle>
      <AvForm model={defaultValues} onValidSubmit={props.handleSubmit}>
        <Row>
          <Col md="8">
            <AvGroup>
              <Label for="legal_name">Legal name of business</Label>
              <AvInput
                name="legal_name_of_business"
                id="legal_name_of_business"
                placeholder="legal name of business"
                required
                onChange={event =>
                  props.handleFieldChange(
                    "legal_name_of_business",
                    event.target.value
                  )
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <Label for="portal_name">Portal name</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  http://erp.xoomsofware.com/
                </InputGroupAddon>
                <AvInput
                  name="name"
                  id="name"
                  placeholder="portal name"
                  onChange={event =>
                    props.handleFieldChange("name", event.target.value)
                  }
                  required
                />
              </InputGroup>

              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>

            <AvGroup>
              <AvField
                type="select"
                name={industry[0].name}
                label="Industry"
                onChange={event =>
                  props.handleFieldChange(
                    "industry_id",
                    event.target.selectedIndex
                  )
                }
                required
              >
                {IndustryOptionList}
              </AvField>
            </AvGroup>
          </Col>
          <Col md="4">
            <div className="text-center">
              <img
                alt="Chris Wood"
                src={avatar1}
                className="rounded-circle img-responsive mt-2"
                width="128"
                height="128"
              />
              <div className="mt-2">
                <Button color="primary">
                  <FontAwesomeIcon icon={faUpload} /> Upload logo
                </Button>
              </div>
              <small>
                For best results, use an image at least 128px by 128px in .jpg
                format
              </small>
            </div>
          </Col>
        </Row>

        {/* Address details */}

        <CardTitle tag="h5">Address details</CardTitle>
        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label for="street_1">Street 1</Label>
              <AvInput
                name="street_one"
                placeholder="Street 1"
                id="street_one"
                required
                onChange={event =>
                  props.handleFieldChange("street_one", event.target.value)
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label for="street_2">Street 2</Label>
              <AvInput
                name="street_two"
                id="street_two"
                placeholder="Street 2"
                required
                onChange={event =>
                  props.handleFieldChange("street_two", event.target.value)
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <AvGroup>
              <Label for="city">City</Label>
              <AvInput
                name="city"
                id="city"
                placeholder="City"
                required
                onChange={event =>
                  props.handleFieldChange("city", event.target.value)
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={4}>
            <AvGroup>
              <Label for="city">State</Label>
              <AvInput
                name="state"
                id="state"
                placeholder="State"
                required
                onChange={event =>
                  props.handleFieldChange("state", event.target.value)
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={2}>
            <AvGroup>
              <Label for="city">Zip/Postal code</Label>
              <AvInput
                name="zip_code"
                id="zip_code"
                placeholder="Zip/Postal code"
                required
                onChange={event =>
                  props.handleFieldChange("zip_code", event.target.value)
                }
              />
              <AvFeedback>This is an error!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>

        <AvField
          type="select"
          name={country[0].name}
          label="Country"
          onChange={event =>
            props.handleFieldChange("country_id", event.target.selectedIndex)
          }
          required
        >
          {CountryOptionList}
        </AvField>

        <Button type="submit" color="primary">
          Save
        </Button>
      </AvForm>
    </Fragment>
  );
};

class GeneralSettings extends React.Component {
  state = {
    organization: "",
    values: {
      industry_id: "",
      country_id: "",
      legal_name_of_business: "",
      name: "",
      logo_url: "",
      street_one: "",
      street_two: "",
      city: "",
      state: "",
      zip_code: ""
    },
    touched: {
      industry_id: false,
      country_id: false,
      legal_name_of_business: false,
      name: false,
      logo_url: false,
      street_one: false,
      street_two: false,
      city: false,
      state: false,
      zip_code: false
    },
    errors: {
      industry_id: null,
      country_id: null,
      legal_name_of_business: null,
      name: null,
      logo_url: null,
      street_one: null,
      street_two: null,
      city: null,
      state: null,
      zip_code: null
    },
    fieldError: {
      industry_id: null,
      country_id: null,
      legal_name_of_business: null,
      name: null,
      logo_url: null,
      street_one: null,
      street_two: null,
      city: null,
      state: null,
      zip_code: null
    },
    submitted: false,
    isLoading: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      organization: nextProps.organization
    });
    console.log(this.state);
  }

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSubmit = async event => {
    // event.preventDefault();
    Object.keys(this.state.values).forEach(key => {
      if (this.state.values[key] === "") {
        const newState = { ...this.state };
        newState.values[key] = this.state.organization[key];
      }
    });
    // for (let key in this.state.values) {
    //   if (this.state.values.hasOwnProperty(key)) {
    //     console.log(`${key} : ${this.state.values[key]}`);
    //   }
    // }
    const organization = this.state.values;
    const id = localStorage.getItem("organization_id");
    this.props.updateOrganization(organization, id);
    console.log(this.state.values);
  };

  render() {
    // const organization = this.state.organization;

    return (
      <Fragment>
        {this.state.organization != "" && (
          <div>
            <PublicInfo
              organization={this.state.organization}
              handleFieldChange={this.handleFieldChange}
              fieldError={this.state.fieldError}
              handleSubmit={this.handleSubmit}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  getOrganization: userAction.getOrganization,
  updateOrganization: userAction.updateOrganization
};
export default withRouter(connect(mapState, actionCreators)(GeneralSettings));
