import React from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";

import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import GeneralSettings from "./GeneralSettings";
import UserSettings from "./UserSettings";
import RoleSettings from "./RoleSettings";
import LocationSettings from "./LocationSettings";
import PreferencesSettings from "./PreferencesSettings";
import TaxSettings from "./TaxSettings";
import NotificationSettings from "./NotificationSettings/Index";

class TabsWithTextLabel extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      organization: {},
      locations: {},
      organizationUsers: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      organization: nextProps.organization,
      locations: nextProps.locations,
      organizationUsers: nextProps.organizationUsers
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { className } = this.props;
    const organization = this.state.organization;
    const locations = this.state.locations;
    const organizationUsers = this.state.organizationUsers;

    return (
      <div className={"tab " + className}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Users
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Roles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "4" })}
              onClick={() => {
                this.toggle("4");
              }}
            >
              Locations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "5" })}
              onClick={() => {
                this.toggle("5");
              }}
            >
              Preferences
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "6" })}
              onClick={() => {
                this.toggle("6");
              }}
            >
              Taxes
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "8" })}
              onClick={() => {
                this.toggle("8");
              }}
            >
              Notifications
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <GeneralSettings organization={organization} />
          </TabPane>
          <TabPane tabId="2">
            <UserSettings organizationUsers={organizationUsers} />
          </TabPane>
          <TabPane tabId="3">
            <RoleSettings />
          </TabPane>
          <TabPane tabId="4">
            <LocationSettings locations={locations} />
          </TabPane>
          <TabPane tabId="5">
            <PreferencesSettings organization={organization} />
          </TabPane>
          <TabPane tabId="6">
            <TaxSettings />
          </TabPane>
          <TabPane tabId="8">
            <NotificationSettings />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

class Settings extends React.Component {
  state = {
    organization: {},
    locations: {},
    organizationUsers: {}
  };

  componentDidMount() {
    Promise.all([
      this.props.getOrganization(),
      this.props.getLocations(),
      this.props.getOrganizationUsers(localStorage.getItem("organization_id"))
    ]).then(data => {
      this.setState({
        organization: data[0].organization.data.data,
        locations: data[1].locations.data.data,
        organizationUsers: data[2].users.data.data
      });
    });
    // this.props.getOrganization().then(organization => {
    //   this.setState({
    //     organization: organization.organization.data.data
    //   });
    // });
    // this.props.getLocations().then(organization => {
    //   this.setState({
    //     locations: organization.organization.data.data
    //   });
    // });
  }

  render() {
    const organization = this.state.organization;
    const locations = this.state.locations;
    const organizationUsers = this.state.organizationUsers;
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Settings</h1>
        <TabsWithTextLabel
          name="Default"
          organization={organization}
          locations={locations}
          organizationUsers={organizationUsers}
        />
      </Container>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  getOrganization: userAction.getOrganization,
  getLocations: userAction.getLocations,
  getOrganizationUsers: userAction.getOrganizationUsers
};
export default withRouter(connect(mapState, actionCreators)(Settings));
