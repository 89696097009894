import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  FormGroup
} from "reactstrap";
import { MoreHorizontal, Edit2 } from "react-feather";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const { SearchBar } = Search;

const tableData = [
  {
    id: 1,
    name: "Pavan Welihinda",
    discription: "test description",
    status: "Complete",
    code: "DEA-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "10%",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 2,
    name: "Pavan Welihinda",
    discription: "test description",
    status: "Pending",
    code: "DEA-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "Rs.90.00",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 3,
    name: "Pavan Welihinda",
    discription: "test description",
    status: "Complete",
    code: "DEA-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "15%",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 4,
    name: "Pavan Welihinda",
    discription: "test description",
    status: "Rejected",
    code: "DEA-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "10%",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 5,
    name: "Pavan Welihinda",
    discription: "test description",
    status: "Complete",
    code: "DEA-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "Rs.110.00",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 6,
    name: "Pavan Welihinda",
    discription: "test description",
    code: "DEA-000001",
    status: "Complete",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "Rs.55.00",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  },
  {
    id: 7,
    name: "Pavan Welihinda",
    discription: "test description",
    code: "DEA-000001",
    status: "Draft",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "Rs.320,800",
    amount: "10%",
    createdDate: "2011/04/25",
    ExpireDate: "2011/04/25",
    dueDate: "2011/04/25"
  }
];

const tableColumns = [
  {
    dataField: "code",
    text: "Code #",
    sort: true
  },

  {
    dataField: "discription",
    text: "Description",
    sort: true
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true
  },

  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: statusFormatter
  },
  {
    dataField: "createdDate",
    text: "Start Date",
    sort: true
  },
  {
    dataField: "ExpireDate",
    text: "Expire Date",
    sort: true
  },
  {
    dataField: "actions",
    text: "Action(s)",
    formatter: actionFormatter,
    sort: false
  }
];

function actionFormatter(cell, row) {
  return (
    <Button
      tag={Link}
      to={{
        pathname: `/discounts/edit/${row.id}`,
        state: {
          item: row
        }
      }}
      color="outline"
      className="mt-n1"
    >
      <Edit2 className="align-middle ml-1" size={18} />
    </Button>
  );
}
function statusFormatter(cell, row) {
  if (cell === "Pending" || cell === "Draft") {
    return <span className="badge badge-warning ml-0 mr-1 mb-1">{cell}</span>;
  } else if (cell === "Rejected") {
    return <span className="badge badge-danger ml-0 mr-1 mb-1">{cell}</span>;
  } else {
    return <span className="badge badge-success ml-0 mr-1 mb-1">{cell}</span>;
  }
}

const RowSelectionTable = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };
  return (
    <Card>
      <ToolkitProvider
        keyField="name"
        data={tableData}
        columns={tableColumns}
        exportCSV
        search
      >
        {props => (
          <div>
            <CardHeader>
              <div className="card-actions float-right">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a">
                    <MoreHorizontal />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>Mark as Active</DropdownItem>
                    <DropdownItem>Mark as Inactive</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <SearchBar {...props.searchProps} />
              </FormGroup>
            </CardHeader>

            <CardBody>
              <BootstrapTable
                bootstrap4
                bordered={false}
                keyField="id"
                data={tableData}
                columns={tableColumns}
                selectRow={selectRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50]
                })}
              />
            </CardBody>
          </div>
        )}
      </ToolkitProvider>
    </Card>
  );
};

const Discounts = () => (
  <Container fluid className="p-0 container-fluid">
    <Button
      tag={Link}
      to="/discounts/new"
      color="primary"
      className="float-right mt-n1"
    >
      <FontAwesomeIcon icon={faPlus} /> New
    </Button>
    <UncontrolledButtonDropdown key={0} className="mr-1 mb-3">
      <DropdownToggle color={"test"}>
        <span className="h3 mb-3">Discounts </span>
      </DropdownToggle>
    </UncontrolledButtonDropdown>

    <RowSelectionTable />
  </Container>
);

export default Discounts;
