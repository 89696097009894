import * as types from "../constants";
import { userService } from "../../services/userService";

import { alertActions } from "./alertActions";

export const userAction = {
  inviteUser,
  getOrganization,
  getLocations,
  addNewLocations,
  updateOrganization,
  getOrganizationUsers
};

function getOrganization() {
  return dispatch => {
    dispatch(request());

    return userService.getOrganization().then(
      organization => dispatch(success(organization)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.GET_ORGANIZATION_REQUEST };
  }
  function success(organization) {
    return { type: types.GET_ORGANIZATION_SUCCESS, organization };
  }
  function failure(error) {
    return { type: types.GET_ORGANIZATION_FAILURE, error };
  }
}

function updateOrganization(organization, id) {
  return dispatch => {
    dispatch(request());

    return userService.updateOrganization(organization, id).then(
      organization => dispatch(success(organization)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.UPDATE_ORGANIZATION_REQUEST };
  }
  function success(organization) {
    return { type: types.UPDATE_ORGANIZATION_SUCCESS, organization };
  }
  function failure(error) {
    return { type: types.UPDATE_ORGANIZATION_FAILURE, error };
  }
}

function getLocations() {
  return dispatch => {
    dispatch(request());

    return userService.getLocations().then(
      locations => dispatch(success(locations)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.GET_LOCATIONS_REQUEST };
  }
  function success(locations) {
    return { type: types.GET_LOCATIONS_SUCCESS, locations };
  }
  function failure(error) {
    return { type: types.GET_LOCATIONS_FAILURE, error };
  }
}

function addNewLocations(location) {
  return dispatch => {
    dispatch(request());

    return userService.addNewLocation(location).then(
      locations => dispatch(success(locations)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.NEW_LOCATIONS_REQUEST };
  }
  function success(location) {
    return { type: types.NEW_LOCATIONS_SUCCESS, location };
  }
  function failure(error) {
    return { type: types.NEW_LOCATIONS_FAILURE, error };
  }
}

function getOrganizationUsers(id) {
  return dispatch => {
    dispatch(request());

    return userService.getOrganizationUsers(id).then(
      users => dispatch(success(users)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.GET_ORGANIZATION_USERS_REQUEST };
  }
  function success(users) {
    return { type: types.GET_ORGANIZATION_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: types.GET_ORGANIZATION_USERS_FAILURE, error };
  }
}

function inviteUser(user) {
  return dispatch => {
    dispatch(request());

    return userService.inviteUser(user).then(
      user => dispatch(success(user)),
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: types.INVITE_USER_REQUEST };
  }
  function success(user) {
    return { type: types.INVITE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: types.INVITE_USER_FAILURE, error };
  }
}
