import React from "react";
import { connect } from "react-redux";
import { authActions } from "../../redux/actions/auth";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert
} from "reactstrap";

import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";

// Externals
import validate from "validate.js";
import _ from "underscore";

// Form validation schema
import schema from "./ResetPasswordSchema";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: "",
        password: "",
        c_password: ""
      },
      errors: {
        email: null,
        password: null,
        c_password: null
      },
      touched: {
        email: false,
        password: false,
        c_password: false
      },
      email: "",
      password: "",
      submitted: false,
      emailValid: true,
      passwordValid: true,
      c_passwordValid: true
    };
  }

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const { errors, touched, isValid, values } = this.state;
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
    if (
      (field === "email" && !(touched.email && errors.email)) ||
      values.email !== ""
    ) {
      this.setState({
        emailValid: true
      });
    }
    if (
      (field === "password" && !(touched.password && errors.password)) ||
      values.password !== ""
    ) {
      this.setState({
        passwordValid: true
      });
    }
    if (
      (field === "c_password" && !(touched.c_password && errors.c_password)) ||
      values.c_password !== ""
    ) {
      this.setState({
        c_passwordValid: true
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { errors, touched, isValid, values } = this.state;
    if ((touched.email && errors.email) || values.email === "") {
      this.setState({
        emailValid: false
      });
    } else if (
      (touched.password && errors.password) ||
      values.password === ""
    ) {
      this.setState({
        passwordValid: false
      });
    } else if (
      (touched.c_password && errors.c_password) ||
      values.c_password === ""
    ) {
      this.setState({
        c_passwordValid: false
      });
    }
    if (
      values.email === "" &&
      values.password === "" &&
      values.c_password === ""
    ) {
      this.setState({
        emailValid: false,
        passwordValid: false,
        c_passwordValid: false
      });
    } else if (values.email === "" && values.password === "") {
      this.setState({
        emailValid: false,
        passwordValid: false
      });
    } else if (values.email === "" && values.c_password === "") {
      this.setState({
        emailValid: false,

        c_passwordValid: false
      });
    } else if (values.password === "" && values.c_password === "") {
      this.setState({
        passwordValid: false,
        c_passwordValid: false
      });
    }
    const { email, password, c_password, token } = this.state.values;
    if (isValid) {
      this.props.resetPassword(email, password, c_password, token);
    }
  };

  // handleSubmit = async event => {
  //   // event.preventDefault();
  //   this.setState({ submitted: true });
  //   const { email, password, c_password, token } = this.state.values;
  //   if (email && password & c_password) {
  //     this.props.resetPassword(email, password, c_password, token);
  //   }
  // };
  render() {
    const { values, errors, touched, isValid } = this.state;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;
    const showPasswordError =
      touched.password && errors.password ? errors.password[0] : false;
    const showC_PasswordError =
      touched.c_password && errors.c_password ? errors.c_password[0] : false;
    console.log(errors);
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    onChange={event =>
                      this.handleFieldChange("email", event.target.value)
                    }
                    invalid={!this.state.emailValid}
                    required
                  />
                  {showEmailError && (
                    <FormFeedback>{errors.email[0]}</FormFeedback>
                  )}

                  {/* <AvFeedback>Email is required</AvFeedback> */}
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    onChange={event =>
                      this.handleFieldChange("password", event.target.value)
                    }
                    invalid={!this.state.passwordValid}
                    required
                  />
                  {showPasswordError && (
                    <FormFeedback>{errors.password[0]}</FormFeedback>
                  )}
                  {/* <AvFeedback>Password is required</AvFeedback> */}
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="c_password"
                    placeholder="Confirm password"
                    onChange={event =>
                      this.handleFieldChange("c_password", event.target.value)
                    }
                    invalid={!this.state.c_passwordValid}
                    required
                  />
                  {showC_PasswordError && (
                    <FormFeedback>{errors.c_password[0]}</FormFeedback>
                  )}

                  {/* <AvFeedback>Confirm password is required</AvFeedback> */}
                </FormGroup>
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    Reset password
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  resetPassword: authActions.resetPassword
};

export default connect(mapState, actionCreators)(ResetPassword);
