import axios from "axios";
import { authHeader } from "../helpers";
export const authService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  getUser
};

// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

function login(username, password) {
  return axios
    .post(API_URL + `auth/login`, { username: username, password: password })

    .then(user => {
      // store user details and token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("access_token", user.data.access_token);
      localStorage.setItem("refresh_token", user.data.refresh_token);
      return user;
    });
}
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

function forgotPassword(email) {
  return axios
    .post(API_URL + `auth/forgot-password`, { email: email })
    .then(user => {
      return user;
    });
}

function resetPassword(email, password, c_password, token) {
  return axios
    .post(API_URL + `auth/forgot-password`, {
      email: email,
      password: password,
      c_password: c_password,
      token: token
    })
    .then(user => {
      return user;
    });
}

function getUser() {
  const requestOptions = {
    headers: authHeader()
  };
  return axios.get(API_URL + `user`, { requestOptions }).then(user => {
    localStorage.setItem(
      "first_name",
      JSON.stringify(user.data.data.first_name)
    );
    localStorage.setItem(
      "organization_id",
      JSON.stringify(user.data.data.organization_id)
    );

    return user;
  });
}
