import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CardTitle, Container, Button } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const tableData = [
  {
    name: "Pavan Welihinda",
    email: "pavan@xoomsoftware.com",
    status: "Active",
    createdDate: "2011/04/25",
    role: "Admin"
  }
];

const tableColumns = [
  {
    dataField: "first_name",
    text: "Name",
    sort: true
  },
  {
    dataField: "email",
    text: "Email",
    sort: false
  },
  {
    dataField: "role",
    text: "Role",
    formatter: roleFormatter,
    sort: false
  },
  {
    dataField: "is_active",
    text: "Status",
    formatter: statusFormatter,
    sort: true
  }
];

function statusFormatter(cell, row) {
  if (cell === 0) {
    return <span className="badge badge-warning ml-0 mr-1 mb-1">Deactive</span>;
  } else {
    return <span className="badge badge-success ml-0 mr-1 mb-1">Active</span>;
  }
}

function roleFormatter(cell, row) {
  return <span>Admin</span>;
}

const RowSelectionTable = props => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };

  const tableData = props.oragnizationUsers;

  return (
    <Fragment>
      <CardTitle tag="h5">
        User details
        <Button
          tag={Link}
          to="/settings/invite-user"
          color="primary"
          className="float-right mt-n1"
        >
          <FontAwesomeIcon icon={faPlus} /> Invite User
        </Button>
      </CardTitle>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="id"
        data={tableData}
        columns={tableColumns}
        selectRow={selectRow}
        pagination={paginationFactory({
          sizePerPage: 10,
          sizePerPageList: [5, 10, 25, 50]
        })}
      />
      {/* </CardBody> */}
    </Fragment>
  );
};

class UserSettings extends React.Component {
  state = {
    oragnizationUsers: ""
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      oragnizationUsers: nextProps.organizationUsers
    });
  }
  render() {
    const oragnizationUsers = this.state.oragnizationUsers;
    return (
      <Container fluid className="p-0 container-fluid">
        {oragnizationUsers !== "" && (
          <RowSelectionTable oragnizationUsers={oragnizationUsers} />
        )}
      </Container>
    );
  }
}

export default UserSettings;
