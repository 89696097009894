import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import React FilePond
import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const options = [{ value: "mrs. Eshan Kunalan", label: "Mrs.Eshan Kunalan" }];

const HorizontalForm = props => {
  console.log(props);
  return (
    <Container fluid className="p-0">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/items">Discounts</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Edit #{props.props.item.id}</BreadcrumbItem>
      </Breadcrumb>
      <h1 className="h3 mb-3">Discount #{props.props.item.id}</h1>
      <Card>
        <CardBody>
          <Form>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Supplier
              </Label>
              <Col sm={10}>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={options}
                  placeholder="Select Supplier"
                  isSearchable
                  isClearable
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Code
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="code"
                  defaultValue={props.props.item.code}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Amount
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="amount"
                  defaultValue={props.props.item.amount}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Description
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="textarea"
                  rows="3"
                  defaultValue={props.props.item.description}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} className="text-sm-right">
                Start Date
              </Label>
              <Col sm={4}>
                <DatePicker
                  placeholderText="Click to select a date"
                  isClearable
                  onChange={date => this.setDate(date)}
                />
              </Col>
              <Label sm={2} className="text-sm-right">
                Expire Date
              </Label>
              <Col sm={4}>
                <DatePicker
                  placeholderText="Click to select a date"
                  isClearable
                  onChange={date => this.setDate(date)}
                />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Save
    </Button>

    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class DiscountEdit extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    isChecked: true,
    item: this.props.location.state.item,
    productOptions: [{ attribute: "", options: "" }]
  };

  render() {
    return (
      <Container>
        <HorizontalForm props={this.state} />

        <ActionPanel />
      </Container>
    );
  }
}

export default DiscountEdit;
