import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap";
import { MoreHorizontal, Edit2 } from "react-feather";

import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const tableData = [
  {
    id: 1,
    name: "t-shirt-XL",
    purchase_order: "PO-0001",
    sku: "T-S-XL",
    stock_in_hand: "10",
    reorder_level: 5,
    invoice: "INV-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "$320,800",
    payables: "$320,800",
    createdDate: "2011/04/25"
  },
  {
    id: 2,
    name: "t-shirt-M",
    purchase_order: "PO-0001",
    sku: "T-S-M",
    stock_in_hand: "10",
    reorder_level: 5,
    invoice: "INV-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "$320,800",
    payables: "$320,800",
    createdDate: "2011/04/25"
  },
  {
    id: 3,
    name: "t-shirt-L",
    purchase_order: "PO-0001",
    sku: "T-S-L",
    stock_in_hand: "10",
    reorder_level: 5,
    invoice: "INV-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "$320,800",
    payables: "$320,800",
    createdDate: "2011/04/25"
  },
  {
    id: 4,
    name: "rice",
    purchase_order: "PO-0001",
    sku: "rice01",
    stock_in_hand: "10",
    reorder_level: 1,
    invoice: "INV-000001",
    refference: "1234",
    email: "pavan@xoomsoftware.com",
    receivables: "$320,800",
    payables: "$320,800",
    createdDate: "2011/04/25"
  }
];

const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    sort: true
  },
  {
    dataField: "sku",
    text: "SKU",
    sort: true
  },
  {
    dataField: "stock_in_hand",
    text: "Stock In Hand",
    sort: true
  },
  {
    dataField: "reorder_level",
    text: "Reorder Level",
    sort: true
  },
  {
    dataField: "actions",
    text: "Action(s)",
    formatter: actionFormatter,
    sort: false
  }
];

function actionFormatter(cell, row) {
  return (
    <Button
      tag={Link}
      to={{
        pathname: `/items/edit/${row.id}`,
        state: {
          item: row
        }
      }}
      color="outline"
      className="mt-n1"
    >
      <Edit2 className="align-middle ml-1" size={18} />
    </Button>
  );
}

const RowSelectionTable = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };
  return (
    <Card>
      <CardHeader>
        <div className="card-actions float-right">
          <UncontrolledDropdown>
            <DropdownToggle tag="a">
              <MoreHorizontal />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Mark as Active</DropdownItem>
              <DropdownItem>Mark as Inactive</DropdownItem>
              <DropdownItem>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>
      <CardBody>
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="id"
          data={tableData}
          columns={tableColumns}
          selectRow={selectRow}
          pagination={paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50]
          })}
        />
      </CardBody>
    </Card>
  );
};

const Invoices = () => (
  <Container fluid className="p-0 container-fluid">
    <Button
      tag={Link}
      to="/items/new"
      color="primary"
      className="float-right mt-n1"
    >
      <FontAwesomeIcon icon={faPlus} /> New
    </Button>
    <UncontrolledButtonDropdown key={0} className="mr-1 mb-3">
      <DropdownToggle caret color={"test"}>
        <span className="h3 mb-3">All Items </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>All</DropdownItem>
        <DropdownItem>Draft</DropdownItem>
        <DropdownItem>Pending Approval</DropdownItem>
        <DropdownItem>Approved</DropdownItem>
        <DropdownItem>Partially Received</DropdownItem>
        <DropdownItem>Unpaid</DropdownItem>
        <DropdownItem>Overdue</DropdownItem>
        <DropdownItem>Paid</DropdownItem>
        <DropdownItem>Void</DropdownItem>
        <DropdownItem divider />
        <DropdownItem>Advanced Search</DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>

    <RowSelectionTable />
  </Container>
);

export default Invoices;
