import * as types from "../constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOCATIONS_REQUEST:
      return {
        loading: true
      };
    case types.GET_LOCATIONS_SUCCESS:
      return action.locations;

    case types.GET_LOCATIONS_FAILURE:
      return {
        error: action.error
      };
    case types.GET_LOCATIONS_REQUEST:
      return {
        loading: true
      };
    case types.NEW_LOCATIONS_SUCCESS:
      return {};

    case types.NEW_LOCATIONS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
