export default {
  legal_name_of_business: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },
  street_one: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },
  street_two: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },

  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },
  state: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  },
  zip_code: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 55
    }
  }
};
