import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Row
} from "reactstrap";
import { Home, Settings } from "react-feather";

import Select from "react-select";

const options = [
  { value: "mr", label: "Mr." },
  { value: "mrs", label: "Mrs." },
  { value: "ms", label: "Ms." },
  { value: "miss", label: "Miss." },
  { value: "dr", label: "Dr." }
];

class HorizontalForm extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  state = {
    activeTab: "1",
    customer: true,
    values: {
      attention: "",
      street_1: "",
      street_2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      fax: "",
      phone: "",
      s_attention: "",
      s_street_1: "",
      s_street_2: "",
      s_city: "",
      s_state: "",
      s_zip_code: "",
      s_country: "asdf",
      s_fax: "",
      s_phone: ""
    }
  };

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.values[field] = value;

    this.setState(newState);
  };

  handleChange = selectedOption => {
    const newState = { ...this.state };
    newState.values.country = selectedOption.value;
    // this.setState({ selectedOption });
  };

  handleCustomerChange = () => {
    this.setState({
      customer: true
    });
  };

  handleVendorChange = () => {
    this.setState({
      customer: false
    });
  };

  handleCopyAdress = () => {
    console.log(this.state);
    const newState = { ...this.state };
    newState.values.s_attention = newState.values.attention;
    newState.values.s_street_1 = newState.values.street_1;
    newState.values.s_street_2 = newState.values.street_2;
    newState.values.s_city = newState.values.city;
    newState.values.s_state = newState.values.state;
    newState.values.s_zip_code = newState.values.zip_code;
    newState.values.s_country = newState.values.country;
    newState.values.s_fax = newState.values.fax;
    newState.values.s_phone = newState.values.phone;
    this.setState({ newState });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    const { className } = this.props;
    console.log(this.props);
    return (
      <div>
        <Card>
          {/* <CardHeader>
            <CardTitle tag="h5">Horizontal form</CardTitle>
          </CardHeader> */}
          <CardBody>
            <Form>
              <FormGroup row>
                <Label sm={2} className="text-sm-right pt-sm-0">
                  Customer Type
                </Label>
                <Col sm={10}>
                  <div className="custom-controls-stacked">
                    <CustomInput
                      inline
                      type="radio"
                      id="business"
                      name="customer_type"
                      label="Business"
                      className="mb-2"
                      defaultChecked
                    />

                    <CustomInput
                      inline
                      type="radio"
                      id="individual"
                      name="customer_type"
                      label="Individual"
                      className="mb-2"
                    />
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Primary Contact
                </Label>
                <Col sm={2}>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    placeholder="Salutation"
                    isSearchable
                    isClearable
                  />
                </Col>
                <Col sm={4}>
                  <Input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                  />
                </Col>
                <Col sm={4}>
                  <Input type="text" name="last_name" placeholder="Last Name" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Comapny Name
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Contact Display Name
                </Label>
                <Col sm={10}>
                  <Input
                    type="text"
                    name="contact_display_name"
                    placeholder="Contact Display Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Contact Email
                </Label>
                <Col sm={10}>
                  <Input
                    type="email"
                    name="contact_email"
                    placeholder={this.props.props.customer.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Contact Phone
                </Label>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="work_phone"
                    placeholder="Work Phone"
                  />
                </Col>
                <Col sm={5}>
                  <Input type="text" name="mobile" placeholder="Mobile" />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2} className="text-sm-right">
                  Website
                </Label>
                <Col sm={10}>
                  <Input type="text" name="website" placeholder="Website" />
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <div className={"tab " + className}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <Home />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <Settings />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Form>
                <Row form>
                  <Col md={6}>
                    <h4 className="tab-title">Billing Address</h4>

                    <FormGroup>
                      <Label className="text-sm-right">Address</Label>

                      <Input
                        type="text"
                        name="street_1"
                        placeholder="Street 1"
                        onChange={event =>
                          this.handleFieldChange("street_1", event.target.value)
                        }
                      />
                      <br></br>
                      <Input
                        type="text"
                        name="street_1"
                        placeholder="Street 2"
                        onChange={event =>
                          this.handleFieldChange("street_2", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">City</Label>

                      <Input
                        type="text"
                        name="city"
                        placeholder="City"
                        onChange={event =>
                          this.handleFieldChange("city", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">State</Label>

                      <Input
                        type="text"
                        name="state"
                        placeholder="State"
                        onChange={event =>
                          this.handleFieldChange("state", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Zip Code</Label>

                      <Input
                        type="text"
                        name="zip_code"
                        placeholder="Zip Code"
                        onChange={event =>
                          this.handleFieldChange("zip_code", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Country</Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={options}
                        placeholder="Select Country"
                        isSearchable
                        isClearable
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Fax</Label>

                      <Input
                        type="text"
                        name="fax"
                        placeholder="Fax"
                        onChange={event =>
                          this.handleFieldChange("fax", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Phone</Label>

                      <Input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        onChange={event =>
                          this.handleFieldChange("phone", event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <h4 className="tab-title">Shipping Address</h4>
                      </Col>

                      <Button
                        className="float-right pull-right "
                        onClick={this.handleCopyAdress}
                      >
                        Copy billing address
                      </Button>
                    </Row>

                    <FormGroup>
                      <Label className="text-sm-right">Address</Label>

                      <Input
                        type="text"
                        name="s_street_1"
                        placeholder="Street 1"
                        value={this.state.values.s_street_1}
                        onChange={event =>
                          this.handleFieldChange(
                            "s_street_1",
                            event.target.value
                          )
                        }
                      />
                      <br></br>
                      <Input
                        type="text"
                        name="s_street_2"
                        placeholder="Street 2"
                        value={this.state.values.s_street_2}
                        onChange={event =>
                          this.handleFieldChange(
                            "s_street_2",
                            event.target.value
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">City</Label>

                      <Input
                        type="text"
                        name="s_city"
                        placeholder="City"
                        value={this.state.values.s_city}
                        onChange={event =>
                          this.handleFieldChange("s_city", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">State</Label>

                      <Input
                        type="text"
                        name="s_state"
                        placeholder="State"
                        value={this.state.values.s_state}
                        onChange={event =>
                          this.handleFieldChange("s_state", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Zip Code</Label>

                      <Input
                        type="text"
                        name="s_zip_code"
                        placeholder="Zip Code"
                        value={this.state.values.s_zip_code}
                        onChange={event =>
                          this.handleFieldChange(
                            "s_zip_code",
                            event.target.value
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Country</Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={options}
                        value={this.state.values.s_country}
                        placeholder="Select Country"
                        isSearchable
                        isClearable
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Fax</Label>

                      <Input
                        type="text"
                        name="s_fax"
                        placeholder="Fax"
                        value={this.state.values.s_fax}
                        onChange={event =>
                          this.handleFieldChange("s_fax", event.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="text-sm-right">Phone</Label>

                      <Input
                        type="text"
                        name="s_phone"
                        placeholder="Phone"
                        value={this.state.values.s_phone}
                        onChange={event =>
                          this.handleFieldChange("s_phone", event.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tabId="2">
              <h4 className="tab-title">Notes</h4>
              <Form>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="textarea"
                    placeholder="Textarea"
                    rows="3"
                  />
                </FormGroup>
              </Form>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Submit
    </Button>

    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class NewContact extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date(),
    customer: this.props.location.state.customer
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/customers"> Customers</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {" "}
            Edit #{this.props.location.state.customer.id}
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          {" "}
          Customer #{this.props.location.state.customer.id}
        </h1>
        <HorizontalForm props={this.state} />

        <ActionPanel />
      </Container>
    );
  }
}

export default NewContact;
