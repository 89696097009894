import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CardTitle, Container, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userAction } from "../../redux/actions/userAction";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// const tableData = [
//   {
//     name: "Odel Alexdra Place",
//     primary: "Yes",
//     status: "Active",
//     createdDate: "2011/04/25",
//     address: "359/1B, Maya mawatha, Makumbura, Pannipitiya"
//   }
// ];

const tableColumns = [
  {
    dataField: "name",
    text: "Name",
    sort: true
  },
  {
    dataField: "address",
    text: "Address",
    sort: false
  },
  {
    dataField: "is_primary",
    text: "Primary",
    formatter: primaryFormatter,
    sort: false
  },
  {
    dataField: "is_active",
    text: "Status",
    formatter: statusFormatter,
    sort: true
  }
];

function statusFormatter(cell, row) {
  if (cell === 0) {
    return <span className="badge badge-warning ml-0 mr-1 mb-1">Deactive</span>;
  } else {
    return <span className="badge badge-success ml-0 mr-1 mb-1">Active</span>;
  }
}

function primaryFormatter(cell, row) {
  if (cell === 0) {
    return <span className="badge badge-warning ml-0 mr-1 mb-1">no</span>;
  } else {
    return <span className="badge badge-success ml-0 mr-1 mb-1">Yes</span>;
  }
}

const RowSelectionTable = props => {
  console.log(props.locations);

  const tableData = props.locations;

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };

  return (
    <Fragment>
      <CardTitle tag="h5">
        Locations
        <Button
          tag={Link}
          to="/settings/new-location"
          color="primary"
          className="float-right mt-n1"
        >
          <FontAwesomeIcon icon={faPlus} /> New
        </Button>
      </CardTitle>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="id"
        data={tableData}
        columns={tableColumns}
        selectRow={selectRow}
        pagination={paginationFactory({
          sizePerPage: 10,
          sizePerPageList: [5, 10, 25, 50]
        })}
      />
      {/* </CardBody> */}
    </Fragment>
  );
};

class LocationSettings extends React.Component {
  state = {
    locations: ""
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      organization: nextProps.organization,
      locations: nextProps.locations
    });
  }

  render() {
    console.log(this.state.locations);
    return (
      <Container fluid className="p-0 container-fluid">
        {this.state.locations !== "" && (
          <RowSelectionTable locations={this.state.locations} />
        )}
      </Container>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  getOrganization: userAction.getLocations
};
export default withRouter(connect(mapState, actionCreators)(LocationSettings));
