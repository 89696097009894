import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const SectionOne = props => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Horizontal form</CardTitle>
      <h6 className="card-subtitle text-muted">Horizontal Bootstrap layout.</h6>
    </CardHeader>
    <CardBody>
      <Form>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Tax Name
          </Label>
          <Col sm={10}>
            <Input type="text" name="tax_name" placeholder="Tax Name" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Rate(%)
          </Label>
          <Col sm={10}>
            <Input type="text" name="rate" placeholder="Rate(%)" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right pt-sm-0"></Label>
          <Col sm={10}>
            <CustomInput
              type="checkbox"
              id="checkbox"
              label="Yes, this tax is a compound tax."
            />
          </Col>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Send
    </Button>

    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class HorizontalForm extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date()
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings">Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Invite User</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">Invite User</h1>
        <SectionOne props={this.state} />

        <ActionPanel />
      </Container>
    );
  }
}

export default HorizontalForm;
