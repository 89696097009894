import async from "../components/Async";

import {
  Book as BookIcon,
  Settings as SettingsIcon,
  Layout as LayoutIcon,
  Monitor as MonitorIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
  FileText as FileTextIcon
} from "react-feather";

// Pages
import Profile from "../pages/pages/Profile";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
// import Boxed from "../pages/layouts/Boxed";
// import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
// import SidebarSticky from "../pages/layouts/SidebarSticky";
// import ThemeClassic from "../pages/layouts/ThemeClassic";
// import ThemeCorporate from "../pages/layouts/ThemeCorporate";
// import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Blank from "../pages/misc/Blank";

import Customers from "../pages/customers/Customers";
import NewCustomer from "../pages/customers/NewCustomer";
import CustomerEdit from "../pages/customers/CustomerEdit";

import SalesOrders from "../pages/SalesOrders/SalesOrders";
import SalesOrderView from "../pages/SalesOrders/SalesOrderView";

import Items from "../pages/Items/Items";
import ItemsEdit from "../pages/Items/ItemsEdit";
import NewItem from "../pages/Items/NewItem";

import Discounts from "../pages/Discounts/Discounts";
import NewDiscount from "../pages/Discounts/NewDiscount";
import DiscountEdit from "../pages/Discounts/DiscountEdit";

import Settings from "../pages/settings";
import NewRole from "../pages/settings/NewRole";
import InviteUser from "../pages/settings/InviteUser";
import NewLocation from "../pages/settings/NewLocation";
import NewTax from "../pages/settings/NewTax";

import Reports from "../pages/Reports/Reports";

import SalesOrderNotification from "../pages/settings/NotificationSettings/SalesOrderNotification";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
// const Analytics = async(() => import("../pages/dashboards/Analytics"));
// const Ecommerce = async(() => import("../pages/dashboards/Ecommerce"));
// const Crypto = async(() => import("../pages/dashboards/Crypto"));
// const Social = async(() => import("../pages/dashboards/Social"));

// Calendar
// const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
// const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
// const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: SignIn,
  children: null
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",

  icon: SlidersIcon,
  containsHome: true,
  component: Default,
  children: null
  // children: [
  //   {
  //     path: "/dashboard/default",
  //     name: "Default",
  //     component: Default
  //   },
  // {
  //   path: "/dashboard/analytics",
  //   name: "Analytics",
  //   component: Analytics
  // },
  // {
  //   path: "/dashboard/e-commerce",
  //   name: "E-commerce",
  //   component: Ecommerce
  // },
  // {
  //   path: "/dashboard/social",
  //   name: "Social",
  //   component: Social
  // },
  // {
  //   path: "/dashboard/crypto",
  //   name: "Crypto",
  //   component: Crypto,
  //   badgeColor: "primary",
  //   badgeText: "New"
  // }
  // ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "12/24",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const customerRoute = {
  path: "/customers",
  name: "Customers",
  icon: MonitorIcon,
  component: Customers,
  children: null
};

const NewCustomerRoute = {
  path: "/customer/new",
  name: "New Contact",
  component: NewCustomer,
  children: null
};

const customerEditRoute = {
  path: "/customers/Edit/:id",
  name: "New Contact",
  component: CustomerEdit,
  children: null
};

const salesOrdersRoute = {
  path: "/sales-orders",
  name: "Sales Orders",
  icon: BookIcon,
  component: SalesOrders,
  children: null
};

const salesOrderViewRoute = {
  path: "/sales-order/:id",
  name: "Sales Order View",
  icon: MonitorIcon,
  component: SalesOrderView,
  children: null
};

const NewSalesOrdersNotificationRoute = {
  path: "/settings/notification/:id",
  name: "Invoice Notification",
  icon: MonitorIcon,
  component: SalesOrderNotification,
  children: null
};

const itemsRoute = {
  path: "/items",
  name: "Items",
  icon: BriefcaseIcon,
  component: Items,
  children: null
};

const newItemsRoute = {
  path: "/items/new",
  name: "New Items",
  icon: BriefcaseIcon,
  component: NewItem,
  children: null
};

const itemsEditRoute = {
  path: "/items/edit/:id",
  name: "Transfer View",
  icon: MonitorIcon,
  component: ItemsEdit,
  children: null
};

const discountRoute = {
  path: "/discounts",
  name: "Discounts",
  icon: DollarSignIcon,
  component: Discounts,
  children: null
};

const newDiscountRoute = {
  path: "/discounts/new",
  name: "New Items",
  icon: BriefcaseIcon,
  component: NewDiscount,
  children: null
};

const discountEditRoute = {
  path: "/discounts/edit/:id",
  name: "New Items",
  icon: BriefcaseIcon,
  component: DiscountEdit,
  children: null
};

const settingsRoute = {
  path: "/settings",
  name: "Settings",
  icon: SettingsIcon,
  component: Settings,
  children: null
};

const ReportsRoute = {
  path: "/reports",
  name: "Reports",
  icon: FileTextIcon,
  component: Reports,
  children: null
};

const NewRoleRoute = {
  path: "/settings/new-role",
  name: "New Role",
  icon: SettingsIcon,
  component: NewRole,
  children: null
};

const NewLocationRoute = {
  path: "/settings/new-location",
  name: "New Location",
  icon: SettingsIcon,
  component: NewLocation,
  children: null
};

const NewTaxRoute = {
  path: "/settings/new-tax",
  name: "New Tax",
  icon: SettingsIcon,
  component: NewTax,
  children: null
};

const inviteUserRoute = {
  path: "/settings/invite-user",
  name: "Invite User",
  icon: SettingsIcon,
  component: InviteUser,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  customerRoute,
  customerEditRoute,
  itemsRoute,
  itemsEditRoute,
  newItemsRoute,
  discountRoute,
  newDiscountRoute,
  discountEditRoute,
  NewRoleRoute,
  NewLocationRoute,
  NewTaxRoute,
  inviteUserRoute,
  salesOrdersRoute,
  salesOrderViewRoute,
  NewCustomerRoute,

  //notificationRoutes
  NewSalesOrdersNotificationRoute,

  // layoutRoutes,

  // calendarRoutes,
  // mapRoutes,
  privateRoutes,
  ReportsRoute,
  pageRoutes,
  settingsRoute
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  salesOrdersRoute,
  itemsRoute,
  customerRoute,
  discountRoute,
  ReportsRoute,
  settingsRoute,
  landingRoutes,

  authRoutes
  // layoutRoutes,

  // calendarRoutes,
  // mapRoutes,
];
