import React from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Table
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const settingsTableDate = [
  {
    name: "Users"
  },
  {
    name: "Export data"
  },
  {
    name: "General preferences"
  },
  {
    name: "Taxes"
  },
  {
    name: "Provide access to Personally Identifiable Information"
  },
  {
    name: "Payment Terms"
  },
  {
    name: "Templates"
  },
  {
    name: "Email Template"
  },
  {
    name: "Manage Integration"
  },
  {
    name: "WorkFlow"
  }
];

const settingsTableColumns = [
  {
    dataField: "name",
    text: "Settings",
    sort: false
  }
];

const reportsTableDate = [
  {
    name: "Dashboard"
  },
  {
    name: "Sales reports"
  },
  {
    name: "Purchase reports"
  },
  {
    name: "Budget reports"
  },
  {
    name: "Inventory reports"
  }
];

const reportsTableColumns = [
  {
    dataField: "name",
    text: "Reports",
    sort: false
  }
];

const SectionOne = props => (
  <Card>
    <CardBody>
      <Form>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Role Name
          </Label>
          <Col sm={10}>
            <Input type="text" name="purchase_order" placeholder="Role Name" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Description
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="reference"
              placeholder="Description"
              rows="3"
            />
          </Col>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

const Contacts = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Contacts</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "15%" }}></th>
              <th className="text-center">Full Access</th>
              <th className="text-center">View</th>
              <th className="text-center">Create</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Customers</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox1" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox2" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox3" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox4" />
              </td>
            </tr>
            <tr>
              <th>Vendors</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </CardBody>
  </Card>
);

const Items = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Items</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "15%" }}></th>
              <th className="text-center">Full Access</th>
              <th className="text-center">View</th>
              <th className="text-center">Create</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Items</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox1" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox2" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox3" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox4" />
              </td>
            </tr>
            <tr>
              <th>Composite Items</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
            </tr>
            <tr>
              <th>Warehouses</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
            </tr>
            <tr>
              <th>Transfer Orders</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
            </tr>
            <tr>
              <th>Inventory Adjustments</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </CardBody>
  </Card>
);

const Sales = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Sales</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "15%" }}></th>
              <th className="text-center">Full Access</th>
              <th className="text-center">View</th>
              <th className="text-center">Create</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
              <th className="text-center">Approve</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Invoices</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_i_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_i_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_i_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_i_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_i_delete" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_i_approve" />
              </td>
            </tr>
            <tr>
              <th>Customer Payments</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_c_p_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_p_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_p_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_p_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_p_delete" />
              </td>
              <td className="text-center"></td>
            </tr>
            <tr>
              <th>Sales Orders</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_s_o_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_s_o_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_s_o_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_s_o_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_s_o_delete" />
              </td>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_s_o_approve"
                />
              </td>
            </tr>
            <tr>
              <th>Package</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_p_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_p_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_p_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_p_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_p_delete" />
              </td>
              <td className="text-center"></td>
            </tr>
            <tr>
              <th>Shipment Order</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_sh_o_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_sh_o_view" />
              </td>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_sh_o_create"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_sh_o_edit" />
              </td>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_sh_o_delete"
                />
              </td>
              <td className="text-center"></td>
            </tr>
            <tr>
              <th>Credit Notes</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_c_n_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_n_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_n_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_n_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_c_n_delete" />
              </td>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_c_n_approve"
                />
              </td>
            </tr>
            <tr>
              <th>Sales Return</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_r_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_delete" />
              </td>
              <td className="text-center"></td>
            </tr>
            <tr>
              <th>Sales Return Receive</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="s_r_r_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_r_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_r_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_r_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="s_r_r_delete" />
              </td>
              <td className="text-center"></td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </CardBody>
  </Card>
);

const Purchase = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Purchase</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "15%" }}></th>
              <th className="text-center">Full Access</th>
              <th className="text-center">View</th>
              <th className="text-center">Create</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
              <th className="text-center">Approve</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Bills</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox1" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox2" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox3" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox4" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox4" />
              </td>
            </tr>
            <tr>
              <th>Vendor Payments</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center"></td>
            </tr>
            <tr>
              <th>Purchase Orders</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox4" />
              </td>
            </tr>
            <tr>
              <th>Purchase Receive</th>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="checkbox" />
              </td>
              <td className="text-center"></td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </CardBody>
  </Card>
);

const Accountant = () => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Purchase</CardTitle>
    </CardHeader>
    <CardBody>
      <Form>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "15%" }}></th>
              <th className="text-center">Full Access</th>
              <th className="text-center">View</th>
              <th className="text-center">Create</th>
              <th className="text-center">Edit</th>
              <th className="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Chart of Accounts</th>
              <td className="text-center">
                <CustomInput
                  defaultChecked
                  type="checkbox"
                  id="a_full_access"
                />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="a_view" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="a_create" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="a_edit" />
              </td>
              <td className="text-center">
                <CustomInput defaultChecked type="checkbox" id="a_delete" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </CardBody>
  </Card>
);

const Settings = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };

  return (
    <Card>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="name"
        data={settingsTableDate}
        columns={settingsTableColumns}
        selectRow={selectRow}
      />
    </Card>
  );
};

const Reports = () => {
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f8f9fa"
  };

  return (
    <Card>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="name"
        data={reportsTableDate}
        columns={reportsTableColumns}
        selectRow={selectRow}
      />
    </Card>
  );
};

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Save
    </Button>
    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class HorizontalForm extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date()
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings">Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>New Role</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">New Role</h1>
        <SectionOne props={this.state} />
        <Contacts />
        <Items />
        <Sales />
        <Purchase />
        <Accountant />
        <Settings />
        <Reports />
        <ActionPanel />
      </Container>
    );
  }
}

export default HorizontalForm;
