import React from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";

const Quill = props => (
  <Card>
    <CardHeader>
      <CardTitle tag="h5">Quill</CardTitle>
      <h6 className="card-subtitle text-muted">
        Modern WYSIWYG editor built for compatibility and extensibility.
      </h6>
    </CardHeader>
    <CardBody>
      <ReactQuill placeholder="Type something" />
    </CardBody>
  </Card>
);

// const Bubble = () => (
//   <Card>
//     <CardHeader>
//       <CardTitle tag="h5">Bubble</CardTitle>
//       <h6 className="card-subtitle text-muted">
//         Bubble is a simple tooltip based theme for Quill.
//       </h6>
//     </CardHeader>
//     <CardBody>
//       <ReactQuill theme="bubble" placeholder="Compase an epic..." />
//     </CardBody>
//   </Card>
// );

class NotificationEditor extends React.Component {
  state = {
    name: this.props.location.state.name
  };
  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings">Settings</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Sales Order Notifications</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3">{this.state.name}</h1>

        <Quill props={this.state} />
      </Container>
    );
  }
}

export default NotificationEditor;
