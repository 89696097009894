import * as types from "../constants";

let user = null;
const initialState = user ? { loggedIn: true, user } : {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_REQUEST:
      return {
        loading: true
      };
    case types.GET_USER_SUCCESS:
      return action.users;

    case types.GET_USER_FAILURE:
      return {
        error: action.error
      };
    case types.GET_ORGANIZATION_USERS_REQUEST:
      return {
        loading: true
      };
    case types.GET_ORGANIZATION_USERS_SUCCESS:
      return action.users;

    case types.GET_ORGANIZATION_USERS_FAILURE:
      return {
        error: action.error
      };
    case types.INVITE_USER_REQUEST:
      return {
        loading: true
      };
    case types.INVITE_USER_SUCCESS:
      return {};

    case types.INVITE_USER_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}
