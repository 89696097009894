import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const options = [{ value: "mrs. Eshan Kunalan", label: "Mrs.Eshan Kunalan" }];

const SectionOne = props => (
  <Card>
    <CardBody>
      <Form>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Supplier
          </Label>
          <Col sm={10}>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={options}
              placeholder="Select Supplier"
              isSearchable
              isClearable
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Code
          </Label>
          <Col sm={10}>
            <Input type="text" name="code" placeholder="Code" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Amount
          </Label>
          <Col sm={10}>
            <Input type="text" name="amount" placeholder="Amount" />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Discription
          </Label>
          <Col sm={10}>
            <Input
              type="textarea"
              rows="3"
              name="discription"
              placeholder="Discription"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} className="text-sm-right">
            Start Date
          </Label>
          <Col sm={4}>
            <DatePicker
              placeholderText="Click to select a date"
              selected={props.date}
              isClearable
              onChange={date => this.setDate(date)}
            />
          </Col>
          <Label sm={2} className="text-sm-right">
            Expire Date
          </Label>
          <Col sm={4}>
            <DatePicker
              placeholderText="Click to select a date"
              selected={props.date}
              isClearable
              onChange={date => this.setDate(date)}
            />
          </Col>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>
);

const ActionPanel = () => (
  <Form>
    <Button color="primary" className="mr-1 mb-1">
      Save
    </Button>
    <Button color="primary" className="mr-1 mb-1" outline>
      Cancel
    </Button>
  </Form>
);

class NewDiscounts extends React.Component {
  state = {
    date: new Date(),
    expected_delivery_date: new Date()
  };

  setDate = date => {
    this.setState({
      date: date
    });
  };

  setExpectedDeliveryDate = date => {
    this.setState({
      expected_delivery_date: date
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/discounts"> Discounts</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active> New Discount</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="h3 mb-3"> New Discount</h1>
        <SectionOne props={this.state} />
        <ActionPanel />
      </Container>
    );
  }
}

export default NewDiscounts;
